import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77024373"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component login" }
const _hoisted_2 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "input__box",
        placeholder: "아이디",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputId) = $event)),
        onKeyup: [
          _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.login()), ["enter"])),
          _cache[2] || (_cache[2] = ($event: any) => (_ctx.showErrorMsg=false))
        ],
        id: "loginId",
        spellcheck: "false"
      }, null, 544), [
        [_vModelText, _ctx.inputId]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "input__box",
        placeholder: "비밀번호",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputPassword) = $event)),
        onKeyup: [
          _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.login()), ["enter"])),
          _cache[5] || (_cache[5] = ($event: any) => (_ctx.showErrorMsg=false))
        ],
        id: "loginPass"
      }, null, 544), [
        [_vModelText, _ctx.inputPassword]
      ]),
      _createElementVNode("button", {
        class: "button__blue login-btn",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.login()))
      }, "로그인")
    ])
  ]))
}