
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import Logo from "../Logo.vue";
// import { useLocaleStore } from '../../store/locale'
import { useLoginStore } from '../../store/login'
import { useUsersStore } from '../../store/users'

export default defineComponent({
  components: {
    Logo
  },
  setup() {
    const router = useRouter();
    const loginUser = computed(() => useUsersStore().getLoginUser);

    const inputId = ref('');
    const inputPassword = ref('');
    const showErrorMsg = ref(false);

    const login = async (): Promise<void> => {
      const res = await useLoginStore().login(inputId.value, inputPassword.value);
      if(res) {
        router.push({name: 'Tdm'});
      }
    }

    return {
      // locale,
      // language,
      login,
      inputId,
      inputPassword,
      showErrorMsg,
      loginUser
    };
  },
});
