import { computed } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { useLoginStore } from '../store/login';

// 페이지 컴포넌트 임포트
import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/MainView.vue";
import JoinView from "@/views/JoinView.vue";
import TdmView from "@/views/TdmView.vue";
import MonitoringView from "@/views/MonitoringView.vue";
import TdmInfoView from "@/views/TdmInfoView.vue";
import MonitoringInfoView from "@/views/MonitoringInfoView.vue";

const routes: Array<RouteRecordRaw> = [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/join',
      name: 'join',
      component: JoinView,
    },
    {
      path: '/',
      name: 'main',
      component: MainView,
      children: [
        {
          path: "tdm",
          name: "Tdm",
          component: TdmView,
        },
        {
          path: "tdm-info",
          name: "TdmInfo",
          component: TdmInfoView,
        },
        {
          path: 'monitoring',
          name: 'Monitoring',
          component: MonitoringView,
        },
        {
          path: "monitoring-info",
          name: "MonitoringInfo",
          component: MonitoringInfoView,
        },
      ]
    },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loginStore = useLoginStore();
  const accessToken = loginStore.getAccessToken;

  if (!accessToken) {
    if (to.name === 'join' || to.name === 'login') {
      next();
    } else {
      next({ path: '/login' });
    }
  } else {
    if (to.name === 'login') {
      next({ path: '/tdm' });
    } else {
      next();
    }
  }
});
export default router;